import { WppActionButton, WppIconExport, WppTypography } from '@wppopen/components-library-react'
import { useNavigate } from 'react-router-dom'

import { usePitchTypes } from 'api/queries/pitch-types/usePitchTypes'
import { navigation } from 'components/SidebarCmp'
import useProjectContext from 'hooks/useProjectContext'

export default function ProjectPreviewCard({
  isCompleted,
  projectName,
  clientName,
  pitchTypeId,
  updatedAt,
  createdAt,
  id,
  rfiSummary,
}: {
  projectName: string
  clientName: string
  pitchTypeId: string
  updatedAt: string
  createdAt: string
  isCompleted: boolean
  id: string
  rfiSummary: string
}) {
  const navigate = useNavigate()
  const { data: pitchTypes } = usePitchTypes()
  const { setState } = useProjectContext()

  const pitchType = pitchTypes?.find(p => p.id === pitchTypeId)?.typeDescription
  return (
    <div
      className="min-w-[294px] flex flex-col gap-4 rounded-8 p-2 bg-[#F8F9FB] hover:cursor-pointer hover:bg-[#e9eaeb] hover:shadow transition-all duration-200 ease-in-out"
      onClick={() => {
        setState(prev => ({
          ...prev,
          projectId: id,
          rfiSummary,
        }))
        navigate(`${navigation.rfiSummary}/${id}`)
      }}
    >
      <div className="flex flex-row items-center justify-between">
        <WppTypography type="s-strong">{projectName}</WppTypography>
        {isCompleted && (
          <WppActionButton variant="secondary">
            <WppIconExport slot="icon-start" />
          </WppActionButton>
        )}
      </div>
      <div className="flex flex-col gap-1">
        <WppTypography type="s-body">{clientName}</WppTypography>
        <WppTypography type="s-body">{pitchType}</WppTypography>
        <WppTypography type="s-midi">Last saved: {new Date(updatedAt || createdAt).toLocaleString()}</WppTypography>
      </div>
    </div>
  )
}
