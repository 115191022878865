// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JjK6k{background-color:var(--wpp-grey-color-100);min-height:calc(100vh - var(--wpp-os-header-height))}.Qcpco{width:100%;margin:0 auto;border-radius:12px;padding:10px 0px 10px 0px;background:var(--wpp-white-color);height:calc(100vh - var(--wpp-os-header-height))}`, "",{"version":3,"sources":["webpack://./src/app/App.module.scss"],"names":[],"mappings":"AAAA,OACE,0CAAA,CACA,oDAAA,CAGF,OACE,UAAA,CACA,aAAA,CACA,kBAAA,CACA,yBAAA,CACA,iCAAA,CACA,gDAAA","sourcesContent":[".container {\n  background-color: var(--wpp-grey-color-100);\n  min-height: calc(100vh - var(--wpp-os-header-height));\n}\n\n.pageContent {\n  width: 100%;\n  margin: 0 auto;\n  border-radius: 12px;\n  padding: 10px 0px 10px 0px;\n  background: var(--wpp-white-color);\n  height: calc(100vh - var(--wpp-os-header-height));\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `JjK6k`,
	"pageContent": `Qcpco`
};
export default ___CSS_LOADER_EXPORT___;
