import { WppTypography } from '@wppopen/components-library-react'
import { useParams } from 'react-router-dom'

import { useRfi } from 'api/queries/rfis/useRfi'

export default function RfiSummaryPage() {
  const params = useParams()
  const { data: rfi, error } = useRfi({
    params: { rfiId: params.projectId || '' },
  })
  return (
    <div>
      <WppTypography type="xl-heading">Summarise RFI / RFP</WppTypography>
      <div className="mt-3 p-6">
        {error && (
          <div className="flex flex-row items-center justify-center">
            <WppTypography type="s-strong">
              No RFI / RFP summary available. Please summarise the RFI / RFP to help the team understand the project.
            </WppTypography>
          </div>
        )}
        {!error && rfi && (
          <WppTypography className="max-w-full whitespace-pre-wrap break-words" type="s-body">
            <span dangerouslySetInnerHTML={{ __html: rfi?.rfiSummary?.replace(/\n/g, '<br />') }} />
          </WppTypography>
        )}
      </div>
    </div>
  )
}
