import React, { Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import AiDraftResponse from 'pages/ai-draft-response/AiDraftResponse'
import AppLayout from 'pages/AppLayout'
import HomePage from 'pages/home/HomePage'
import NewProjectPage from 'pages/new-project/NewProjectPage'
import ProposedResponseStructurePage from 'pages/proposed-response-structure/ProposedResponseStructurePage'
import RelevantNotesPage from 'pages/relevant-notes/RelevantNotesPage'
import RfiSummaryPage from 'pages/rfi-summary/RfiSummaryPage'

// const HomePage = React.lazy(() => import('pages/home/HomePage'))
// const NewProjectPage = React.lazy(() => import('pages/new-project/NewProjectPage'))
// const ProposedResponseStructurePage = React.lazy(
//   () => import('pages/proposed-response-structure/ProposedResponseStructurePage'),
// )
// const RelevantNotesPage = React.lazy(() => import('pages/relevant-notes/RelevantNotesPage'))
// const RfiSummaryPage = React.lazy(() => import('pages/rfi-summary/RfiSummaryPage'))
// const AiDraftResponse = React.lazy(() => import('pages/ai-draft-response/AiDraftResponse'))
const AnswerQuestionsPage = React.lazy(() => import('pages/answer-questions/AnswerQuestionsPage'))

export default function RoutesCmp() {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route path="/" element={<AppLayout />}>
          <Route index element={<Navigate replace to="rfi-helper-tool/home" />} />
          <Route path="rfi-helper-tool/home" element={<HomePage />} />
          <Route path="rfi-helper-tool/new-project" element={<NewProjectPage />} />
          <Route path="rfi-helper-tool/rfi-summary/:projectId" element={<RfiSummaryPage />} />
          <Route path="rfi-helper-tool/relevant-notes/:projectId" element={<RelevantNotesPage />} />
          <Route
            path="rfi-helper-tool/proposed-response-structure/:projectId"
            element={<ProposedResponseStructurePage />}
          />
          <Route path="rfi-helper-tool/answer-questions/:projectId" element={<AnswerQuestionsPage />} />
          <Route path="rfi-helper-tool/ai-draft-response/:projectId" element={<AiDraftResponse />} />
          <Route path="*" element={<Navigate replace to="/rfi-helper-tool/home" />} />
        </Route>
      </Routes>
    </Suspense>
  )
}
