import { WppButton, WppIconAdd, WppSpinner, WppTypography } from '@wppopen/components-library-react'
import { useOs } from '@wppopen/react'
import { useNavigate } from 'react-router-dom'

import { useRfis } from 'api/queries/rfis/useRfis'
import { navigation } from 'components/SidebarCmp'

import EmptyStateHome from './EmptyStateHome'
import ProjectPreviewCard from './ProjectPreviewCard'

export default function HomePage() {
  const navigate = useNavigate()
  const { osContext } = useOs()
  const {
    data: projects = [],
    error,
    isLoading,
  } = useRfis({
    params: {
      tenantId: osContext.tenant.id,
      userId: osContext.userDetails.id,
    },
  })

  return (
    <>
      <div className="flex flex-row items-center justify-between">
        <WppTypography type="2xl-heading">My Projects</WppTypography>
        <WppButton variant="primary" onClick={() => navigate(navigation.newProject)}>
          <WppIconAdd slot="icon-start" />
          Add New
        </WppButton>
      </div>
      {error && <EmptyStateHome />}
      {!error && isLoading && (
        <div className="flex flex-row items-center justify-center h-52">
          <WppSpinner size="l" />
        </div>
      )}
      {!error && projects && (
        <div className="mt-3 flex flex-row justify-start flex-wrap gap-4">
          {projects?.map((project: any) => <ProjectPreviewCard key={project.id} {...project} />)}
        </div>
      )}
    </>
  )
}
