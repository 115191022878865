import { createUseQuery } from 'api/common/createUseQuery'
import { fetchTaskStatus } from 'api/fetchers/task-status/taskStatus'
import { ProgressApiRes } from 'components/LoaderProgressWithDescription'
import { ApiQueryKeys } from 'constants/apiQueryKeys'

export const useTasksStatus = createUseQuery({
  queryKey: ApiQueryKeys.TASKS_STATUS,
  fetcher: fetchTaskStatus,
  selector: res => res?.data ?? ({} as ProgressApiRes),
})
