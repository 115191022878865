import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { FullscreenAppCustomProps } from '@wppopen/core'
import { OsProvider } from '@wppopen/react'

import { App } from 'app/App'
import { ApiProvider } from 'providers/ApiProvider'

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
})

export const Root = (props: FullscreenAppCustomProps) => (
  // <StrictMode>
  <OsProvider {...props}>
    <QueryClientProvider client={queryClient}>
      <ApiProvider>
        <App />
      </ApiProvider>
    </QueryClientProvider>
  </OsProvider>
  // </StrictMode>
)
