import { WppActionButton, WppIconArrow, WppTypography } from '@wppopen/components-library-react'
import { useState } from 'react'
import { useParams } from 'react-router-dom'

import { useRfi } from 'api/queries/rfis/useRfi'
import { useUseCases } from 'api/queries/use-cases/useUseCases'
import DownloadZip from 'components/DownloadZip'
import { ProjectDetails } from 'types/rfis/rfi'
import { UseCase } from 'types/use-cases/useCase'

import FileCmp from './FileCmp'

function RelevantNotesPage({ rfi }: { rfi: ProjectDetails }) {
  const [selectedFile, setSelectedFile] = useState<UseCase | null>(null)
  const { data: useCases, isLoading } = useUseCases({ params: { rfiId: rfi.id } })
  if (selectedFile) {
    return (
      <div>
        <div className="flex flex-row items-center gap-4">
          <WppActionButton onClick={() => setSelectedFile(null)} variant="secondary">
            <WppIconArrow direction="left" slot="icon-start" />
          </WppActionButton>
          <WppTypography type="xl-heading">Relevant Cases and Pitches / {selectedFile.title}</WppTypography>
        </div>
        <div className="bg-[#F8F9FB] rounded-lg p-4 mt-4">
          <WppTypography color="#121619" type="s-strong">
            {selectedFile.title}
          </WppTypography>
          <WppTypography className="mt-3" color="#4D5358" type="s-body">
            <WppTypography className="max-w-full whitespace-pre-wrap break-words" type="s-body">
              <span dangerouslySetInnerHTML={{ __html: selectedFile.summary.replace(/\n/g, '<br />') }} />
            </WppTypography>
          </WppTypography>
        </div>
      </div>
    )
  }
  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-row items-center justify-between">
        <WppTypography type="xl-heading">Relevant Cases and Pitches</WppTypography>
        <DownloadZip
          disabled={useCases.every(u => !u.fileUrl)}
          urls={useCases.filter(u => u.fileUrl).map(u => u.fileUrl)}
        />
      </div>

      <div className="flex flex-row items-center flex-wrap justify-start gap-3">
        {isLoading && <div>Loading...</div>}
        {!isLoading &&
          useCases &&
          useCases?.map(useCase => (
            <FileCmp key={useCase.id} useCase={useCase} onClick={useCase => setSelectedFile(useCase)} />
          ))}
      </div>
    </div>
  )
}

export default function RelevantNotesPageWrapper() {
  const params = useParams()
  const {
    data: rfi,
    isLoading,
    error,
  } = useRfi({
    params: { rfiId: params.projectId || '' },
  })

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return (
      <div className="flex flex-row items-center justify-center">
        <WppTypography type="s-strong">
          No RFI / RFP summary available. Please summarise the RFI / RFP to help the team understand the project.
        </WppTypography>
      </div>
    )
  }

  return <RelevantNotesPage rfi={rfi} />
}
