export enum ApiQueryKeys {
  USER_SETTINGS = 'user_settings',
  RFIS = 'rfis',
  RFI = 'rfi',
  MARKETS = 'markets',
  RFI_QUESTIONS = 'rfi_questions',
  RFI_QUESTION = 'rfi_question',
  PITCH_TYPES = 'pitch_types',
  SUMMARIES = 'summaries',
  USE_CASES = 'use_cases',
  TASKS_STATUS = 'tasks_status',
  REGIONS = 'regions',
}
