import { WppButton, WppEmptyNothingFound, WppIconPlus, WppTypography } from '@wppopen/components-library-react'
import { useNavigate } from 'react-router-dom'

import { navigation } from 'components/SidebarCmp'

export default function EmptyStateHome() {
  const navigate = useNavigate()
  return (
    <div className="flex flex-col gap-6 items-center justify-center w-full h-[456px]">
      <WppEmptyNothingFound />
      <div className="flex flex-col gap-2 items-center justify-center">
        <WppTypography type="m-strong">No Projects to Show</WppTypography>
        <WppTypography type="m-body">Create a new project to get started</WppTypography>
      </div>
      <WppButton onClick={() => navigate(navigation.newProject)} size="m">
        <WppIconPlus slot="icon-start" />
        Add New
      </WppButton>
    </div>
  )
}
