import { useOs } from '@wppopen/react'
import { useEffect, PropsWithChildren, useState } from 'react'

import { rfiHelperApi, masterDataApi } from 'api'

const apiInstances = [rfiHelperApi, masterDataApi]

export const ApiProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isReady, setIsReady] = useState(false)
  const { osApi, osContext } = useOs()

  const tenantId = osContext.tenant.id

  useEffect(() => {
    const interceptors = apiInstances.map(instance =>
      instance.client.interceptors.request.use(
        instanceConfig => {
          const bearer = osApi.getAccessToken()
          if (bearer) {
            instanceConfig!.headers!.Authorization = `Bearer ${bearer}`
            // instanceConfig!.headers!.Authorization =
            //   'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJSWTNGSWRndVNiSDZFYzJiUS0xQ2VwY1k5RDlXT1ZiWk15Vy1VdVQwUEFnIn0.eyJleHAiOjE3MTEwOTA1MDgsImlhdCI6MTcxMTA1NDY4NCwiYXV0aF90aW1lIjoxNzExMDU0NTA4LCJqdGkiOiI1MmM0MzdlNC05MmRkLTQ3YzctYjAxMS1jMDEyYjFhZDQ4MWEiLCJpc3MiOiJodHRwczovL2F1dGgub3Mud3BwLmNvbS9hdXRoL3JlYWxtcy9hei1iZXRhIiwiYXVkIjoiYWNjb3VudCIsInN1YiI6ImQ2ZDY3Y2JjLTBjMWYtNGIwNS1iMmM5LWJjMjk5ODE4NmIzMiIsInR5cCI6IkJlYXJlciIsImF6cCI6ImJveC1zdGFnaW5nLWZlIiwic2Vzc2lvbl9zdGF0ZSI6ImVmNjVjNDhiLTZkOWQtNDI1My05ZWE0LTZmZWI0MDcwOGRkNSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJvZmZsaW5lX2FjY2VzcyIsInVtYV9hdXRob3JpemF0aW9uIiwiZGVmYXVsdC1yb2xlcy1hei1iZXRhIl19LCJyZXNvdXJjZV9hY2Nlc3MiOnsiYWNjb3VudCI6eyJyb2xlcyI6WyJtYW5hZ2UtYWNjb3VudCIsIm1hbmFnZS1hY2NvdW50LWxpbmtzIiwidmlldy1wcm9maWxlIl19fSwic2NvcGUiOiJvcGVuaWQgZW1haWwgcHJvZmlsZSIsInNpZCI6ImVmNjVjNDhiLTZkOWQtNDI1My05ZWE0LTZmZWI0MDcwOGRkNSIsImVtYWlsX3ZlcmlmaWVkIjpmYWxzZSwibmFtZSI6IkRlbmlzIFN1Ym90aWMiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJkZW5pcy5zdWJvdGljQHdwcC5jb20iLCJnaXZlbl9uYW1lIjoiRGVuaXMiLCJmYW1pbHlfbmFtZSI6IlN1Ym90aWMiLCJlbWFpbCI6ImRlbmlzLnN1Ym90aWNAd3BwLmNvbSJ9.sNn0NPksGOJpS3JB2tgJ_0xpWG50HNvU-HXjbhvhBW_Vwb8zgmN0vnywhDBkvcyq2_H_beAZ0Qu9CuE32WWGRZpWkXn6UXInf2Ky5abjhKkbCeFxLgr3kQ7lvTiEs85TiV2orj3HtkXrccKsAru9GcinivkI0FXTVys_NOUlvWuS7QFq8gkdqosguoWNyIGaYfQYkw5jT0Zx0voRBygXnKpr2nV2Qe18paZBVqJGpvEG4UfxqAonID4xQFtdnISZXSNrbKVOwqiiqtc5grv4c7cqlI8VH7wOqq1-U7GmqKoujYdiI8XifGn_XIXvXRFY1EtTVDg-3xlBzKb_ftxtwQ'
          }

          if (tenantId) {
            instanceConfig!.headers!['X-Tenant-Id'] = tenantId
          }

          return instanceConfig
        },
        error => Promise.reject(error),
      ),
    )

    setIsReady(true)

    return () => {
      interceptors.forEach((interceptor, index) => {
        apiInstances[index].client.interceptors.request.eject(interceptor)
      })
    }
    // eslint-disable-next-line
  }, [osApi.getAccessToken])

  return <>{isReady && children}</>
}
