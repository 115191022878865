import { Outlet } from 'react-router-dom'

import { SidebarCmp } from 'components/SidebarCmp'

const AppLayout = () => {
  return (
    <div style={{ display: 'flex' }}>
      <SidebarCmp />
      <div style={{ flexGrow: 1, padding: '20px' }}>
        <Outlet />
      </div>
    </div>
  )
}

export default AppLayout
