import { WppTypography } from '@wppopen/components-library-react'
import * as R from 'ramda'
import { useParams } from 'react-router-dom'

import { useRfiQuestions } from 'api/queries/rfis/useRfiQuestions'
import { RfiQuestion } from 'types/rfis/rfi'

import styles from './ProposedResponseStructure.module.scss'

interface ConvertedQuestion {
  id: string
  name: string
  questions: RfiQuestion[]
}

const convertQuestionsToCategories = R.groupBy<RfiQuestion>(R.prop('rfiCategoryId'))

export default function ProposedResponseStructurePage() {
  const urlParams = useParams()
  const { data: responseStructure } = useRfiQuestions({
    params: {
      rfiId: urlParams.projectId,
    },
  })

  const groupedQuestions = convertQuestionsToCategories(responseStructure)
  const result = R.map(categoryId => {
    const categoryQuestions = groupedQuestions[categoryId]
    const { rfiCategoryName } = categoryQuestions![0]
    return {
      id: categoryId.toString(),
      name: rfiCategoryName,
      questions: R.sortBy(R.prop('position'))(
        categoryQuestions?.map(q => ({
          ...q,
          id: q.id.toString(),
        })) || [],
      ),
    } as ConvertedQuestion
  }, R.keys(groupedQuestions))
  return (
    <div className={styles.container}>
      <WppTypography type="xl-heading">Proposed Response Structure</WppTypography>
      <div className="mt-5">
        <WppTypography className="mt-4" type="s-strong">
          Based on the RFP document, I would recommend to structure the presentation as follows:{' '}
        </WppTypography>
      </div>
      <div className="mt-3 pb-24">
        {result.map((category, i) => (
          <ol className="mt-3 list-decimal pl-6" key={category.id}>
            <WppTypography type="xl-heading">
              {i + 1}. {category.name}
            </WppTypography>
            <ul className="list-disc pl-6 mt-2">
              {category.questions.map(question => (
                <li key={question.id}>
                  <WppTypography type="s-body">{question.questionText}</WppTypography>
                </li>
              ))}
            </ul>
          </ol>
        ))}
      </div>
    </div>
  )
}
