// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iFxpN{max-height:calc(100dvh - 100px - var(--wpp-os-header-height));overflow-y:scroll}`, "",{"version":3,"sources":["webpack://./src/pages/proposed-response-structure/ProposedResponseStructure.module.scss"],"names":[],"mappings":"AAAA,OACE,6DAAA,CACA,iBAAA","sourcesContent":[".container {\n  max-height: calc(100dvh - 100px - var(--wpp-os-header-height));\n  overflow-y: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `iFxpN`
};
export default ___CSS_LOADER_EXPORT___;
