import { rfiHelperApi } from 'api'
import { ProjectDetails } from 'types/rfis/rfi'

export const fetchRfis = ({ tenantId, userId }: { tenantId: string; userId: string }) =>
  rfiHelperApi.get<ProjectDetails[]>('/rfis', {
    params: {
      tenantId,
      userId,
    },
  })
