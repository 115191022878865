import axios from 'axios'

interface Params {
  signedUrl: string
  file: File
}

export const uploadProcessFileApi = ({ signedUrl, file }: Params) =>
  axios.put(signedUrl, file, {
    headers: {
      'Content-Type': file.type || 'application/octet-stream',
      'x-goog-content-length-range': '1,5000000',
    },
  })
